import React from 'react';

const PoliticaCookies = () => {
  return (
    <div className="min-h-screen bg-white px-4 py-8 max-w-5xl mx-auto mt-20">
      <h1 className="text-3xl font-serif font-bold text-primary mb-6">Política de Cookies</h1>

      <section className="mb-8">
        <h2 className="text-2xl font-serif text-neutral mb-4">1. ¿Qué son las cookies?</h2>
        <p className="mb-4">
          Las cookies son pequeños archivos de texto que los sitios web almacenan en su dispositivo (ordenador, tablet, teléfono móvil) cuando los visita. Las cookies ayudan al sitio web a recordar información sobre su visita, como su idioma preferido y otras configuraciones.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-serif text-neutral mb-4">2. Tipos de cookies que utilizamos</h2>
        <div className="space-y-4">
          <div>
            <h3 className="text-xl font-serif text-neutral mb-2">Cookies técnicas (necesarias)</h3>
            <p>Son aquellas que permiten al usuario la navegación a través de la página web y la utilización de las diferentes opciones o servicios que en ella existen.</p>
          </div>
          <div>
            <h3 className="text-xl font-serif text-neutral mb-2">Cookies analíticas</h3>
            <p>Permiten analizar el comportamiento de los usuarios de forma anónima para mejorar los servicios ofrecidos.</p>
          </div>
          <div>
            <h3 className="text-xl font-serif text-neutral mb-2">Cookies de personalización</h3>
            <p>Permiten al usuario acceder al servicio con algunas características predefinidas en función de una serie de criterios.</p>
          </div>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-serif text-neutral mb-4">3. ¿Cómo gestionamos las cookies?</h2>
        <p className="mb-4">
          De acuerdo con la normativa vigente, necesitamos su consentimiento para utilizar cookies no esenciales. Puede modificar sus preferencias de cookies en cualquier momento a través de nuestro panel de configuración de cookies.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-serif text-neutral mb-4">4. ¿Cómo deshabilitar las cookies?</h2>
        <p className="mb-4">
          Puede permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones de su navegador de internet. A continuación, le indicamos los enlaces donde puede encontrar información sobre cómo gestionar las cookies en los principales navegadores:
        </p>
        <ul className="list-disc pl-6 mb-4 space-y-2">
          <li><a href="https://support.google.com/chrome/answer/95647?hl=es" className="text-primary hover:text-primary-dark">Google Chrome</a></li>
          <li><a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias" className="text-primary hover:text-primary-dark">Mozilla Firefox</a></li>
          <li><a href="https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d" className="text-primary hover:text-primary-dark">Microsoft Edge</a></li>
          <li><a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac" className="text-primary hover:text-primary-dark">Safari</a></li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-serif text-neutral mb-4">5. Actualizaciones y cambios</h2>
        <p className="mb-4">
          Podemos actualizar esta Política de Cookies en cualquier momento. Le recomendamos que revise esta política cada vez que acceda a nuestro sitio web para estar informado sobre cómo y para qué utilizamos las cookies.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-serif text-neutral mb-4">6. Contacto</h2>
        <p className="mb-4">
          Si tiene alguna pregunta sobre nuestra Política de Cookies, puede contactarnos a través de:
        </p>
        <ul className="list-disc pl-6 mb-4 space-y-2">
          <li><strong>Email:</strong> jrosalesphotos@gmail.com</li>
          <li><strong>Teléfono:</strong> 623 060 220</li>
        </ul>
      </section>
    </div>
  );
};

export default PoliticaCookies;