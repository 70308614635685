import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import heroImage from '../../assets/images/hero2.jpg';
import profileImage from '../../assets/images/retrato.jpg';

const Home = () => {
  const [featuredImages, setFeaturedImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const importImages = async () => {
      try {
        const imageContext = require.context('../../assets/images/Destacados', false, /\.(png|jpe?g|svg)$/, 'eager');
        const keys = imageContext.keys();
        
        const loadedImages = await Promise.all(
          keys.map(async (key) => {
            const module = await imageContext(key);
            return {
              src: module.default || module,
              name: key.replace('./', ''),
            };
          })
        );
        
        setFeaturedImages(loadedImages);
      } catch (error) {
        console.error('Error loading featured images:', error);
        setFeaturedImages([]);
      }
    };

    importImages();
  }, []);

  return (
    <div>
      {/* Hero Section */}
      <section className="hero" style={{ backgroundImage: `url(${heroImage})` }}>
        <div className="hero-content">
          <h1 className="hero-title">Julia Rosales Photography</h1>
          <p className="hero-subtitle">Capturando momentos únicos y especiales</p>
          <Link to="/contact" className="btn-accent">
            Contáctame
          </Link>
        </div>
      </section>

      {/* About Section */}
      <section className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
            <div className="relative">
              <div className="aspect-w-4 aspect-h-5 rounded-full overflow-hidden shadow-xl">
                  <img
                    src={profileImage}
                    alt="Julia Rosales"
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>
            <div>
              <h2 className="section-title">Soy Julia, fotógrafa profesional con más de 10 años de experiencia capturando historias a través de la lente</h2>
              <p className="text-lg text-gray-600 mb-6">
              Mi camino en la fotografía comenzó con una simple cámara que mi padre me regaló cuando era niña. Desde entonces, he transformado esa curiosidad inicial en una carrera dedicada a plasmar momentos únicos, emociones genuinas y detalles que perduran en el tiempo.
              </p>
              <p className="text-lg text-gray-600 mb-6">
              Hoy, combino técnica, creatividad y una profunda conexión con mis clientes para crear imágenes que cuentan historias reales. Ya sea un retrato íntimo, un evento especial o un proyecto comercial, cada encargo es una oportunidad para capturar algo irrepetible.
              </p>
              <Link to="/about" className="btn-primary">
                Conoce más sobre mí
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Featured Gallery Section */}
      <section className="py-16 bg-primary-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="section-title">Trabajos Destacados</h2>
            <p className="section-subtitle">Una selección de mis mejores fotografías</p>
          </div>

          <div className="columns-1 md:columns-2 lg:columns-3 gap-4 space-y-4">
            {featuredImages.map((image, index) => (
              <div 
                key={index} 
                className="break-inside-avoid mb-4 group relative overflow-hidden rounded-lg shadow-lg cursor-pointer"
                onClick={() => setSelectedImage(image)}
              >
                <img
                  src={image.src}
                  alt={`Trabajo destacado ${index + 1}`}
                  className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                />
                <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-opacity duration-300">
                  <button className="absolute inset-0 w-full h-full flex items-center justify-center text-white opacity-0 group-hover:opacity-100">
                    Ver Imagen
                  </button>
                </div>
              </div>
            ))}
          </div>

          <div className="text-center mt-12">
            <Link to="/portfolio" className="btn-accent">
              Ver Todo el Portafolio
            </Link>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="section-title">Lo que las parejas dicen</h2>
            <p className="section-subtitle">Experiencias compartidas por quienes confiaron en mí</p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-primary-50 p-8 rounded-lg shadow-md">
              <p className="text-neutral italic mb-6">
                "La forma en la que Julia capturó nuestra boda fue mágica. Supo estar en el momento preciso para capturar cada detalle, cada emoción. No solo logró capturar lo tangible de ese día, sino también el amor y la alegría que se respiraba. Las fotografías son un tesoro que guardaremos para siempre."
              </p>
              <p className="font-serif text-neutral-dark">Cristina Cabrera</p>
            </div>

            <div className="bg-primary-50 p-8 rounded-lg shadow-md">
              <p className="text-neutral italic mb-6">
                "Carlos y yo no podíamos estar más felices con nuestras fotos de boda. La profesionalidad de Julia fue excepcional. Su manera discreta de trabajar nos permitió sentirnos cómodos en todo momento, y el resultado final nos dejó sin palabras. Cada foto cuenta una historia, y ahora tenemos un álbum lleno de momentos únicos."
              </p>
              <p className="font-serif text-neutral-dark">Ana Pascual</p>
            </div>

            <div className="bg-primary-50 p-8 rounded-lg shadow-md">
              <p className="text-neutral italic mb-6">
                "Qué gran decisión hicimos al contar con Julia para nuestro gran día. Nos las puso muy fácil y nos sentimos como en casa desde que la conocimos. Su forma de trabajar es muy natural, sus fotografías transmiten autenticidad. Más que una fotógrafa, se convirtió en una amiga que supo plasmar nuestra esencia."
              </p>
              <p className="font-serif text-neutral-dark">Jennifer Viñas</p>
            </div>
          </div>
        </div>
      </section>

      {/* Modal */}
      {selectedImage && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center p-4"
          onClick={() => setSelectedImage(null)}
        >
          <button 
            className="absolute top-4 right-4 text-white text-4xl hover:text-gray-300"
            onClick={() => setSelectedImage(null)}
          >
            ×
          </button>
          <img
            src={selectedImage.src}
            alt="Selected"
            className="max-h-[90vh] max-w-[90vw] object-contain"
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      )}
    </div>
  );
};

export default Home;