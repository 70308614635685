import React from 'react';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-3xl font-serif mb-8">Panel de Administración</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <Link
          to="/admin/upload"
          className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200"
        >
          <h2 className="text-xl font-semibold mb-2">Subir Fotos</h2>
          <p className="text-gray-600">Añade nuevas fotos a tu portafolio</p>
        </Link>

        <Link
          to="/admin/manage"
          className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200"
        >
          <h2 className="text-xl font-semibold mb-2">Gestionar Portafolio</h2>
          <p className="text-gray-600">Organiza y edita tu colección de fotos</p>
        </Link>

        <Link
          to="/admin/messages"
          className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200"
        >
          <h2 className="text-xl font-semibold mb-2">Ver Mensajes</h2>
          <p className="text-gray-600">Revisa los mensajes de contacto recibidos</p>
        </Link>
      </div>
    </div>
  );
};

export default Dashboard;