import React, { useState, useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AuthContext from '../../context/auth/authContext';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const authContext = useContext(AuthContext);
  const { isAuthenticated, logout } = authContext || { isAuthenticated: false };

  useEffect(() => {
    const handleScroll = () => {
      if (isHomePage) {
        const scrollPosition = window.scrollY;
        setIsScrolled(scrollPosition > 50);
      } else {
        setIsScrolled(true); // Always show solid header on other pages
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Call it immediately to set initial state
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isHomePage]);

  // Add this new useEffect after the existing one
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const onLogout = () => {
    logout();
  };

  return (
    <nav className={`fixed w-full top-0 z-50 transition-all duration-300 ${isHomePage ? (isScrolled ? 'bg-white shadow-lg' : 'bg-transparent') : 'bg-white shadow-lg'}`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-20">
          <div className="flex">
            <div className="flex-shrink-0 flex items-center">
              <Link to="/" className="flex items-center">
                <img 
                  src={require(`../../assets/images/${isScrolled ? 'logo negro.png' : 'logo borde.png'}`)} 
                  alt="Julia Rosales Photography" 
                  className="h-36 w-auto object-contain" // Adjusted height and added object-contain
                />
              </Link>
            </div>
          </div>
          
          {/* Mobile menu button */}
          <div className="-mr-2 flex items-center md:hidden">
            <button
              onClick={toggleMenu}
              className={`inline-flex items-center justify-center p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 ${isScrolled ? 'text-gray-400 hover:text-gray-500 hover:bg-gray-100' : 'text-white hover:text-gray-300'}`}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className={`${isOpen ? 'hidden' : 'block'} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
              <svg
                className={`${isOpen ? 'block' : 'hidden'} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          
          {/* Desktop menu */}
          <div className="hidden md:flex md:items-center md:space-x-6">
            <Link to="/" className={`px-4 py-2 rounded-md text-sm font-medium transition-colors duration-300 ${isScrolled ? 'text-gray-700 hover:text-gray-900 hover:bg-gray-100' : 'text-white hover:text-gray-200'}`}>
              Inicio
            </Link>
            <Link to="/about" className={`px-4 py-2 rounded-md text-sm font-medium transition-colors duration-300 ${isScrolled ? 'text-gray-700 hover:text-gray-900 hover:bg-gray-100' : 'text-white hover:text-gray-200'}`}>
              Sobre Mí
            </Link>
            <Link to="/portfolio" className={`px-4 py-2 rounded-md text-sm font-medium transition-colors duration-300 ${isScrolled ? 'text-gray-700 hover:text-gray-900 hover:bg-gray-100' : 'text-white hover:text-gray-200'}`}>
              Portafolio
            </Link>
            <Link to="/contact" className={`px-4 py-2 rounded-md text-sm font-medium transition-colors duration-300 ${isScrolled ? 'text-gray-700 hover:text-gray-900 hover:bg-gray-100' : 'text-white hover:text-gray-200'}`}>
              Contacto
            </Link>
            {isAuthenticated && (
              <>
                <Link to="/admin" className={`px-3 py-2 rounded-md text-sm font-medium transition-colors duration-300 ${isScrolled ? 'text-gray-700 hover:text-gray-900' : 'text-white hover:text-gray-200'}`}>
                  Admin
                </Link>
                <button
                  onClick={onLogout}
                  className={`px-3 py-2 rounded-md text-sm font-medium transition-colors duration-300 ${isScrolled ? 'text-gray-700 hover:text-gray-900' : 'text-white hover:text-gray-200'}`}
                >
                  Cerrar Sesión
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div className={`${isOpen ? 'block' : 'hidden'} md:hidden ${isScrolled ? 'bg-white' : 'bg-black bg-opacity-50'}`}>
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <Link
            to="/"
            className={`block px-3 py-2 rounded-md text-base font-medium ${isScrolled ? 'text-gray-700 hover:text-gray-900 hover:bg-gray-50' : 'text-white hover:text-gray-200 hover:bg-black hover:bg-opacity-30'}`}
            onClick={() => setIsOpen(false)}
          >
            Inicio
          </Link>
          <Link
            to="/about"
            className={`block px-3 py-2 rounded-md text-base font-medium ${isScrolled ? 'text-gray-700 hover:text-gray-900 hover:bg-gray-50' : 'text-white hover:text-gray-200 hover:bg-black hover:bg-opacity-30'}`}
            onClick={() => setIsOpen(false)}
          >
            Sobre Mí
          </Link>
          <Link
            to="/portfolio"
            className={`block px-3 py-2 rounded-md text-base font-medium ${isScrolled ? 'text-gray-700 hover:text-gray-900 hover:bg-gray-50' : 'text-white hover:text-gray-200 hover:bg-black hover:bg-opacity-30'}`}
            onClick={() => setIsOpen(false)}
          >
            Portafolio
          </Link>
          <Link
            to="/contact"
            className={`block px-3 py-2 rounded-md text-base font-medium ${isScrolled ? 'text-gray-700 hover:text-gray-900 hover:bg-gray-50' : 'text-white hover:text-gray-200 hover:bg-black hover:bg-opacity-30'}`}
            onClick={() => setIsOpen(false)}
          >
            Contacto
          </Link>
          {isAuthenticated && (
            <>
              <Link
                to="/admin"
                className={`block px-3 py-2 rounded-md text-base font-medium ${isScrolled ? 'text-gray-700 hover:text-gray-900 hover:bg-gray-50' : 'text-white hover:text-gray-200 hover:bg-black hover:bg-opacity-30'}`}
                onClick={() => setIsOpen(false)}
              >
                Admin
              </Link>
              <button
                onClick={() => {
                  onLogout();
                  setIsOpen(false);
                }}
                className={`block w-full text-left px-3 py-2 rounded-md text-base font-medium ${isScrolled ? 'text-gray-700 hover:text-gray-900 hover:bg-gray-50' : 'text-white hover:text-gray-200 hover:bg-black hover:bg-opacity-30'}`}
              >
                Cerrar Sesión
              </button>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;