import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ViewMessages = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    loadMessages();
  }, []);

  const loadMessages = async () => {
    try {
      const res = await axios.get('/api/contact');
      setMessages(res.data);
      setLoading(false);
    } catch (err) {
      setError('Error al cargar los mensajes');
      setLoading(false);
    }
  };

  if (loading) return <div className="text-center py-8">Cargando...</div>;
  if (error) return <div className="text-red-600 text-center py-8">{error}</div>;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-3xl font-serif mb-8">Mensajes de Contacto</h1>

      {messages.length === 0 ? (
        <p className="text-center text-gray-600">No hay mensajes nuevos</p>
      ) : (
        <div className="grid gap-6">
          {messages.map(message => (
            <div key={message._id} className="bg-white rounded-lg shadow-md p-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div>
                  <h3 className="text-sm font-bold text-gray-700">Nombre:</h3>
                  <p className="text-gray-600">{message.name}</p>
                </div>
                <div>
                  <h3 className="text-sm font-bold text-gray-700">Email:</h3>
                  <p className="text-gray-600">{message.email}</p>
                </div>
                {message.phone && (
                  <div>
                    <h3 className="text-sm font-bold text-gray-700">Teléfono:</h3>
                    <p className="text-gray-600">{message.phone}</p>
                  </div>
                )}
                <div>
                  <h3 className="text-sm font-bold text-gray-700">Fecha:</h3>
                  <p className="text-gray-600">
                    {new Date(message.date).toLocaleDateString('es-ES', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit'
                    })}
                  </p>
                </div>
              </div>
              <div>
                <h3 className="text-sm font-bold text-gray-700">Mensaje:</h3>
                <p className="text-gray-600 whitespace-pre-wrap">{message.message}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ViewMessages;