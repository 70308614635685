import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from '../../context/auth/authContext';

const PrivateRoute = ({ children }) => {
  const authContext = useContext(AuthContext);
  const { isAuthenticated, loading } = authContext;

  // Check if user is not authenticated and not loading
  if (!isAuthenticated && !loading) {
    return <Navigate to="/admin/login" replace />;
  }

  return children;
};

export default PrivateRoute;