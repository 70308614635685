import React from 'react';

const AvisoLegal = () => {
  return (
    <div className="min-h-screen bg-white px-4 py-8 max-w-5xl mx-auto mt-20">
      <h1 className="text-3xl font-serif font-bold text-primary mb-6">Aviso Legal</h1>
      
      <section className="mb-8">
        <h2 className="text-2xl font-serif text-neutral mb-4">1. Información Legal</h2>
        <p className="mb-4">
          En cumplimiento de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico (LSSI-CE), se informa que la titularidad de este sitio web, www.juliarosales.com, (en adelante, Sitio Web) la ostenta:
        </p>
        <ul className="list-disc pl-6 mb-4 space-y-2">
          <li><strong>Titular:</strong> Julia Rosales</li>
          <li><strong>NIF:</strong> 44293737S</li>
          <li><strong>Teléfono:</strong> 623 060 220</li>
          <li><strong>Email:</strong> jrosalesphotos@gmail.com</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-serif text-neutral mb-4">2. Objeto</h2>
        <p className="mb-4">
          El presente aviso legal regula el uso y utilización del sitio web, del que es titular Julia Rosales.
          La navegación por el sitio web atribuye la condición de usuario del mismo e implica la aceptación plena y sin reservas de todas y cada una de las disposiciones incluidas en este aviso legal.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-serif text-neutral mb-4">3. Responsabilidad</h2>
        <p className="mb-4">
          Julia Rosales no se hace responsable de los daños y perjuicios que se pudieran derivar de interferencias, omisiones, interrupciones, virus informáticos, averías telefónicas o desconexiones en el funcionamiento operativo de este sistema electrónico, motivadas por causas ajenas al titular.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-serif text-neutral mb-4">4. Propiedad Intelectual e Industrial</h2>
        <p className="mb-4">
          Los derechos de propiedad intelectual e industrial derivados de todos los textos, imágenes, así como de los medios y formas de presentación y montaje de sus páginas pertenecen, por sí mismos o como cesionaria, a Julia Rosales. Serán, por tanto, obras protegidas por la legislación sobre propiedad intelectual e industrial vigente.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-serif text-neutral mb-4">5. Legislación Aplicable y Jurisdicción</h2>
        <p className="mb-4">
          Para la resolución de todas las controversias o cuestiones relacionadas con el presente sitio web o de las actividades en él desarrolladas, será de aplicación la legislación española, a la que se someten expresamente las partes, siendo competentes para la resolución de todos los conflictos derivados o relacionados con su uso los Juzgados y Tribunales correspondientes.
        </p>
      </section>
    </div>
  );
};

export default AvisoLegal;