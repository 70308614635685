import React, { useState, useEffect } from 'react';

const Portfolio = () => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const importImages = async () => {
      try {
        // Forzar una nueva importación del contexto
        const imageContext = require.context('../../assets/images/Galeria', false, /\.(png|jpe?g|svg)$/, 'eager');
        const keys = imageContext.keys();
        
        const loadedImages = await Promise.all(
          keys.map(async (key) => {
            const module = await imageContext(key);
            return {
              src: module.default || module,
              name: key.replace('./', ''),
            };
          })
        );
        
        console.log('Imágenes cargadas:', loadedImages.length); // Para debugging
        setImages(loadedImages);
      } catch (error) {
        console.error('Error loading images:', error);
        setImages([]);
      }
    };

    importImages();
  }, []);

  return (
    <div className="bg-white pt-20">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h1 className="section-title text-4xl">Mi Portafolio</h1>
          <p className="section-subtitle max-w-3xl mx-auto">
            Explora mi trabajo y descubre mi estilo fotográfico
          </p>
        </div>

        <div className="columns-1 md:columns-2 lg:columns-3 gap-4 space-y-4">
          {images.map((image, index) => (
            <div 
              key={index} 
              className="break-inside-avoid mb-4 group relative overflow-hidden rounded-lg shadow-md cursor-pointer"
              onClick={() => setSelectedImage(image)}
            >
              <img
                src={image.src}
                alt={`Portfolio ${index + 1}`}
                className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
              />
              <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-opacity duration-300" />
            </div>
          ))}
        </div>
      </div>

      {/* Modal */}
      {selectedImage && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center p-4"
          onClick={() => setSelectedImage(null)}
        >
          <button 
            className="absolute top-4 right-4 text-white text-4xl hover:text-accent"
            onClick={() => setSelectedImage(null)}
          >
            ×
          </button>
          <img
            src={selectedImage.src}
            alt="Selected"
            className="max-h-[90vh] max-w-[90vw] object-contain"
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      )}
    </div>
  );
};

export default Portfolio;