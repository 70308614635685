import React, { useState } from 'react';
import axios from 'axios';

const UploadPhoto = () => {
  const [file, setFile] = useState(null);
  const [category, setCategory] = useState('');
  const [description, setDescription] = useState('');
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState({ type: '', text: '' });

  const onFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      setMessage({ type: 'error', text: 'Por favor selecciona una imagen' });
      return;
    }

    const formData = new FormData();
    formData.append('photo', file);
    formData.append('category', category);
    formData.append('description', description);

    try {
      setUploading(true);
      await axios.post('/api/portfolio', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      setMessage({ type: 'success', text: 'Imagen subida exitosamente' });
      setFile(null);
      setCategory('');
      setDescription('');
      // Reset file input
      const fileInput = document.querySelector('input[type="file"]');
      if (fileInput) fileInput.value = '';
    } catch (err) {
      setMessage({
        type: 'error',
        text: 'Error al subir la imagen. Por favor intenta nuevamente.'
      });
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-serif mb-6">Subir Nueva Foto</h1>
      
      <div className="bg-white shadow-lg rounded-lg p-6">
        {message.text && (
          <div
            className={`p-4 mb-4 rounded ${message.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}
          >
            {message.text}
          </div>
        )}
        
        <form onSubmit={onSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="photo">
              Seleccionar Foto *
            </label>
            <input
              type="file"
              id="photo"
              accept="image/*"
              onChange={onFileChange}
              className="w-full"
              required
            />
          </div>
          
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="category">
              Categoría *
            </label>
            <select
              id="category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            >
              <option value="">Seleccionar categoría</option>
              <option value="retratos">Retratos</option>
              <option value="bodas">Bodas</option>
              <option value="paisajes">Paisajes</option>
              <option value="eventos">Eventos</option>
            </select>
          </div>
          
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
              Descripción
            </label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              rows="3"
            ></textarea>
          </div>
          
          <div className="flex items-center justify-end">
            <button
              type="submit"
              disabled={uploading}
              className={`bg-gray-800 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${uploading ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              {uploading ? 'Subiendo...' : 'Subir Foto'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UploadPhoto;