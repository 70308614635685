import emailjs from '@emailjs/browser';

// Initialize EmailJS with your public key
emailjs.init('sNJUaYNwKTDfLTB7r');

// EmailJS service configuration
const SERVICE_ID = 'service_x7xxk8n';
const CLIENT_TEMPLATE_ID = 'template_9yywipl';
const ADMIN_TEMPLATE_ID = 'template_ukdz5dm';

// Function to send emails using EmailJS
export const sendContactEmails = async (formData) => {
  try {
    // Send email to client
    await emailjs.send(
      SERVICE_ID,
      CLIENT_TEMPLATE_ID,
      {
        to_name: formData.name,
        to_email: formData.email,
        from_name: "Julia Rosales Photography",
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        message: formData.message,
        eventType: formData.eventType,
        eventDate: formData.eventDate,
        location: formData.location,
        budget: formData.budget
      }
    );

    // Send email to admin
    await emailjs.send(
      SERVICE_ID,
      ADMIN_TEMPLATE_ID,
      {
        from_name: formData.name,
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        eventType: formData.eventType,
        eventDate: formData.eventDate,
        location: formData.location,
        budget: formData.budget,
        message: formData.message
      }
    );

    return { success: true };
  } catch (error) {
    console.error('Error sending emails:', error);
    return { success: false, error: error.message };
  }
};