import React from 'react';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <div className="bg-white pt-20"> {/* Added pt-20 for navbar space */}
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h1 className="section-title text-4xl">Sobre Mí</h1>
          <p className="section-subtitle max-w-3xl mx-auto">
            Conozca mi historia y mi pasión por capturar momentos únicos a través de la fotografía
          </p>
        </div>
        
        <div className="flex flex-col md:flex-row items-center gap-12 mb-16">
          <div className="md:w-1/2">
            <div className="aspect-w-4 aspect-h-5 rounded-lg overflow-hidden shadow-lg">
              <img src={require('../../assets/images/Julia.jpg')} alt="Julia Rosales" className="w-full h-full object-cover" />
            </div>
          </div>
          
          <div className="md:w-1/2">
            <h2 className="section-title">Julia Rosales</h2>
            <p className="text-lg text-gray-600 mb-6">
              La fotografía es mi forma de contar historias sin palabras. Cada imagen que capturo es un instante 
              de vida congelado en el tiempo, una emoción preservada para siempre. Mi viaje comenzó en las calles 
              de Granada, donde aprendí a ver la belleza en los detalles más pequeños.
            </p>
            <p className="text-lg text-gray-600 mb-6">
              Me especializo en fotografía de bodas y retratos, donde cada sesión es una oportunidad única 
              para capturar la esencia de las personas. No solo tomo fotografías, creo recuerdos que perdurarán 
              generaciones, documentando esos momentos fugaces que hacen la vida extraordinaria.
            </p>
            <p className="text-lg text-gray-600">
              Mi estilo combina el fotoperiodismo con un toque artístico, buscando siempre ese equilibrio 
              perfecto entre luz natural y emoción genuina. Cada historia es única, y mi objetivo es capturarla 
              de una manera que refleje su autenticidad y belleza particular.
            </p>
          </div>
        </div>
        
        <div className="mb-16">
          <h2 className="section-title text-center">Mi Filosofía</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-primary-50 p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-serif font-semibold text-primary mb-3">Arte y Técnica</h3>
              <p className="text-neutral">
                Fusiono la precisión técnica con la sensibilidad artística. Cada imagen es una 
                cuidadosa composición de luz, momento y emoción, creando fotografías que son tanto 
                documentales como artísticas.
              </p>
            </div>
            
            <div className="bg-gray-50 p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold text-gray-900 mb-3">Momentos Naturales</h3>
              <p className="text-gray-600">
                Mi enfoque discreto permite que los momentos se desarrollen naturalmente. Capturo la 
                espontaneidad y la autenticidad de cada instante, creando imágenes que respiran vida.
              </p>
            </div>
            
            <div className="bg-gray-50 p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold text-gray-900 mb-3">Conexión Personal</h3>
              <p className="text-gray-600">
                Construyo una relación de confianza con cada cliente. Esta conexión es fundamental 
                para crear un ambiente donde las emociones fluyen naturalmente, resultando en 
                fotografías más íntimas y significativas.
              </p>
            </div>
          </div>
        </div>
        
        <div className="text-center">
          <h2 className="section-title">¿Listo para capturar tus momentos especiales?</h2>
          <p className="section-subtitle max-w-3xl mx-auto">
            Me encantaría ser parte de tu historia y ayudarte a crear recuerdos que durarán toda la vida.
          </p>
          <Link to="/contact" className="btn-accent">
            Contáctame
          </Link>
        </div>
      </div>
    </div>
  );
};

export default About;