import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Layout Components
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';

// Page Components
import Home from './components/pages/Home';
import About from './components/pages/About';
import Portfolio from './components/pages/Portfolio';
import Contact from './components/pages/Contact';
import NotFound from './components/pages/NotFound';
import CookieConsent from './components/common/CookieConsent';
import PoliticaCookies from './components/PoliticaCookies';
import PoliticaPrivacidad from './components/PoliticaPrivacidad';
import AvisoLegal from './components/AvisoLegal';

// Admin Components
import Login from './components/admin/Login';
import Dashboard from './components/admin/Dashboard';
import UploadPhoto from './components/admin/UploadPhoto';
import ManagePortfolio from './components/admin/ManagePortfolio';
import ViewMessages from './components/admin/ViewMessages';

// Auth Context
import AuthState from './context/auth/AuthState';
import PrivateRoute from './components/routing/PrivateRoute';

// CSS
import './App.css';

function App() {
  return (
    <AuthState>
      <div className="flex flex-col min-h-screen">
        <Helmet>
          <title>Julia Rosales Photography | Capturando momentos únicos</title>
          <meta name="description" content="Julia Rosales Photography - Fotógrafa profesional especializada en retratos, bodas y paisajes. Capturamos tus momentos más especiales con un estilo único y profesional." />
          <meta name="keywords" content="fotografía, fotógrafa profesional, retratos, bodas, paisajes, Julia Rosales, fotografía artística" />
          <meta property="og:title" content="Julia Rosales Photography | Capturando momentos únicos" />
          <meta property="og:description" content="Fotógrafa profesional especializada en retratos, bodas y paisajes. Capturamos tus momentos más especiales con un estilo único y profesional." />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://juliarosalesphotography.com" />
          <meta property="og:image" content="/assets/images/hero.jpeg" />
          <link rel="canonical" href="https://juliarosalesphotography.com" />
        </Helmet>
        
        <Navbar />
        
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/contact" element={<Contact />} />
            
            {/* Admin Routes */}
            <Route path="/admin/login" element={<Login />} />
            <Route path="/admin" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route path="/admin/upload" element={<PrivateRoute><UploadPhoto /></PrivateRoute>} />
            <Route path="/admin/manage" element={<PrivateRoute><ManagePortfolio /></PrivateRoute>} />
            <Route path="/admin/messages" element={<PrivateRoute><ViewMessages /></PrivateRoute>} />
            
            {/* Legal Pages */}
            <Route path="/politica-cookies" element={<PoliticaCookies />} />
            <Route path="/politica-privacidad" element={<PoliticaPrivacidad />} />
            <Route path="/aviso-legal" element={<AvisoLegal />} />
            
            {/* 404 Route */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        
        <Footer />
        <CookieConsent />
      </div>
    </AuthState>
  );
}

export default App;