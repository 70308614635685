import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAcceptAll = () => {
    localStorage.setItem('cookieConsent', 'all');
    setIsVisible(false);
  };

  const handleEssentialOnly = () => {
    localStorage.setItem('cookieConsent', 'essential');
    setIsVisible(false);
  };

  const handleReject = () => {
    localStorage.setItem('cookieConsent', 'rejected');
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-neutral p-4 text-white z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center space-x-4">
          <div className="text-sm flex-grow pr-4">
            Utilizamos cookies para mejorar tu experiencia. Puedes elegir qué cookies aceptar. Lee nuestra
            {' '}
            <Link to="/politica-cookies" className="underline hover:text-accent">
              política de cookies
            </Link>
            {' '}
            para más información.
          </div>
          <div className="flex items-center space-x-1">
            <button
              onClick={handleAcceptAll}
              className="text-xs bg-accent text-neutral px-2 py-0.5 rounded hover:bg-accent-dark transition-colors duration-300"
            >
              Aceptar todas
            </button>
            <button
              onClick={handleEssentialOnly}
              className="text-xs bg-primary text-white px-2 py-0.5 rounded hover:bg-primary-dark transition-colors duration-300"
            >
              Solo esenciales
            </button>
            <button
              onClick={handleReject}
              className="text-xs bg-gray-600 text-white px-2 py-0.5 rounded hover:bg-gray-700 transition-colors duration-300"
            >
              Rechazar todas
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;