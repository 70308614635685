import React from 'react';

const PoliticaPrivacidad = () => {
  return (
    <div className="min-h-screen bg-white px-4 py-8 max-w-5xl mx-auto mt-20">
      <h1 className="text-3xl font-serif font-bold text-primary mb-6">Política de Privacidad</h1>

      <section className="mb-8">
        <h2 className="text-2xl font-serif text-neutral mb-4">1. Responsable del Tratamiento</h2>
        <p className="mb-4">
          De conformidad con lo establecido en el Reglamento General de Protección de Datos (UE) 2016/679, le informamos que los datos personales que nos proporcione serán tratados por:
        </p>
        <ul className="list-disc pl-6 mb-4 space-y-2">
          <li><strong>Titular:</strong> Julia Rosales</li>
          <li><strong>NIF:</strong> 44293737S</li>
          <li><strong>Teléfono:</strong> 623 060 220</li>
          <li><strong>Email:</strong> jrosalesphotos@gmail.com</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-serif text-neutral mb-4">2. Finalidad del Tratamiento</h2>
        <p className="mb-4">
          Los datos personales que nos proporciona serán utilizados con las siguientes finalidades:
        </p>
        <ul className="list-disc pl-6 mb-4 space-y-2">
          <li>Gestionar las solicitudes de información y contacto recibidas a través del formulario de contacto.</li>
          <li>Enviar comunicaciones relacionadas con los servicios fotográficos solicitados.</li>
          <li>Gestionar la relación comercial y administrativa con nuestros clientes.</li>
          <li>Enviar, si ha dado su consentimiento expreso, comunicaciones comerciales sobre nuestros servicios.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-serif text-neutral mb-4">3. Legitimación</h2>
        <p className="mb-4">
          El tratamiento de sus datos está basado en:
        </p>
        <ul className="list-disc pl-6 mb-4 space-y-2">
          <li>La ejecución de un contrato o prestación de servicios solicitados.</li>
          <li>El consentimiento expreso del interesado para finalidades específicas.</li>
          <li>El interés legítimo del responsable del tratamiento.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-serif text-neutral mb-4">4. Conservación de los Datos</h2>
        <p className="mb-4">
          Los datos personales se conservarán mientras se mantenga la relación comercial o durante los años necesarios para cumplir con las obligaciones legales. En el caso de los datos tratados con base en su consentimiento, estos se conservarán hasta que solicite su supresión.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-serif text-neutral mb-4">5. Derechos del Usuario</h2>
        <p className="mb-4">
          Puede ejercer sus derechos de acceso, rectificación, supresión, limitación, oposición y portabilidad de los datos mediante correo electrónico a jrosalesphotos@gmail.com, adjuntando copia de su DNI o documento identificativo equivalente.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-serif text-neutral mb-4">6. Medidas de Seguridad</h2>
        <p className="mb-4">
          Hemos adoptado las medidas técnicas y organizativas necesarias para garantizar la seguridad de los datos de carácter personal y evitar su alteración, pérdida, tratamiento o acceso no autorizado.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-serif text-neutral mb-4">7. Reclamaciones</h2>
        <p className="mb-4">
          Puede presentar una reclamación ante la Agencia Española de Protección de Datos (www.aepd.es) si considera que el tratamiento de sus datos personales no cumple con la normativa vigente.
        </p>
      </section>
    </div>
  );
};

export default PoliticaPrivacidad;