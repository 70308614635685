import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ManagePortfolio = () => {
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    loadPhotos();
  }, []);

  const loadPhotos = async () => {
    try {
      const res = await axios.get('/api/portfolio');
      setPhotos(res.data);
      setLoading(false);
    } catch (err) {
      setError('Error al cargar las fotos');
      setLoading(false);
    }
  };

  const deletePhoto = async (id) => {
    if (window.confirm('¿Estás seguro de que deseas eliminar esta foto?')) {
      try {
        await axios.delete(`/api/portfolio/${id}`);
        setPhotos(photos.filter(photo => photo._id !== id));
      } catch (err) {
        setError('Error al eliminar la foto');
      }
    }
  };

  if (loading) return <div className="text-center py-8">Cargando...</div>;
  if (error) return <div className="text-red-600 text-center py-8">{error}</div>;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-3xl font-serif mb-8">Gestionar Portafolio</h1>

      {photos.length === 0 ? (
        <p className="text-center text-gray-600">No hay fotos en el portafolio</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {photos.map(photo => (
            <div key={photo._id} className="bg-white rounded-lg shadow-md overflow-hidden">
              <img
                src={photo.url}
                alt={photo.description}
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <p className="text-sm font-medium text-gray-600 mb-2">
                  Categoría: {photo.category}
                </p>
                {photo.description && (
                  <p className="text-gray-500 text-sm mb-4">{photo.description}</p>
                )}
                <div className="flex justify-end">
                  <button
                    onClick={() => deletePhoto(photo._id)}
                    className="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded text-sm"
                  >
                    Eliminar
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ManagePortfolio;